import * as React from "react"
import { graphql } from "gatsby"
import ProgramRow from "../components/program-row"
 
import Layout from "../components/layout"
import Seo from "../components/seo"
 
const ProgramsIndex = ({ data, location }) => {
 
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  const programs = data.allProgram.edges
  // const episodes = data.allEpisode.edges
 
  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All Programs" />
        
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All posts" /> 
      <ol style={{ listStyle: `none` }}>
        {programs.map(post => {
          const title = post.node.name || post.node.id

          return (
            <li key={post.node.id}>
              <ProgramRow program={post.node} />
            </li>
          )
        })}
      </ol>
    </Layout>
  )
}

export default ProgramsIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
    allProgram {
      edges {
        node {
          ...Program
           
        }
      }
    }
    allEpisode {
      edges {
        node {
          ...Episode
        }
      }
    }
  }
`
